import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import RehypeReact from "rehype-react";
import Layout from "../components/layout";

const titlePropTypes = {
  children: PropTypes.node.isRequired
};

function Title1({ children, ...props }) {
  return (
    <h1 className="title-1" {...props}>
      {children}
    </h1>
  );
}

Title1.propTypes = titlePropTypes;

function Title2({ children, ...props }) {
  return (
    <h2 className="title-2" {...props}>
      {children}
    </h2>
  );
}

Title2.propTypes = titlePropTypes;

function Subtitle1({ children, ...props }) {
  return (
    <h3 className="subtitle-1" {...props}>
      {children}
    </h3>
  );
}

Subtitle1.propTypes = titlePropTypes;

function Subtitle2({ children, ...props }) {
  return (
    <h4 className="subtitle-2" {...props}>
      {children}
    </h4>
  );
}

Subtitle2.propTypes = titlePropTypes;

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: Title1,
    h2: Title2,
    h3: Subtitle1,
    h4: Subtitle2
  }
}).Compiler;

export default function DocPage({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout>
      <Title1>{post.frontmatter.title}</Title1>
      {renderAst(post.htmlAst)}
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;

DocPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({}),
      frontmatter: PropTypes.shape({
        title: PropTypes.string
      })
    })
  })
};

DocPage.defaultProps = {
  data: {
    markdownRemark: {
      html: "",
      frontmatter: {
        title: ""
      }
    }
  }
};
