import React from "react";
import { version } from "../../package.json";

export default function Header() {
  return (
    <header className="border-b border-just-black-12">
      <div className="-mb-px">
        <div className="columns py-5 md:py-6">
          <span className="column w-full">
            <h1 className="inline-block">
              <span className="font-just-script title-2 leading-none">
                Just.
              </span>
              <span className="subtitle-1 leading-none">Design System</span>
            </h1>{" "}
            <span className="italic">v{version}</span>
          </span>
        </div>
      </div>
    </header>
  );
}
