import React from "react";

export default function Footer() {
  return (
    <footer className="border-t border-just-black-12 flex-none text-sm leading-loose">
      <div className="py-8 md:py-12">
        <div className="columns">
          <div className="column w-full">© 2018 Just Group plc</div>
        </div>
      </div>
    </footer>
  );
}
