export default [
  "introduction",
  "approach-and-team",
  "tools",
  "___",
  "guidelines",
  "building-blocks",
  "components",
  "patterns",
  "___",
  "downloads",
  "faq"
];
