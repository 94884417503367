import React from "react";
import PropTypes from "prop-types";
import "@justgarage/ui-components/dist/jui.css";
import "prism-themes/themes/prism-vs.css";
import { StaticQuery, graphql } from "gatsby";
import { sortBy } from "lodash";
import SideBar from "./side-bar/side-bar";
import Header from "./header";
import Footer from "./footer";

const dataToSidebarLinkTitles = data =>
  sortBy(
    data.allMarkdownRemark.edges.map(edge => ({
      link: edge.node.fields.slug,
      title: edge.node.frontmatter.title,
      sidebarSection: edge.node.fields.sidebarSection
    })),
    ["sidebarSection", "title"]
  );

const query = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
            sidebarSection
          }
        }
      }
    }
  }
`;

export default function Layout({ children }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className="min-h-screen flex flex-col">
          <Header />
          <div className="flex-1 flex-basis-auto flex-col columns md:flex-row">
            <div className="column md:w-1/4 xl:w-1/6">
              <SideBar linkTitles={dataToSidebarLinkTitles(data)} />
            </div>
            <div className="column md:w-3/4 xl:w-5/6 md:py-8 md:pr-8 lg:py-12 lg:pr-12 ">
              {children}
            </div>
          </div>
          <Footer />
        </div>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node
};

Layout.defaultProps = {
  children: ""
};
