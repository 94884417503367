import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { capitalize } from "lodash";
import tableOfContents from "./sidebar-structure";

const renderLink = linkTitle => (
  <Link
    className="link link--no-underline block"
    key={linkTitle.link}
    to={linkTitle.link}
    activeClassName="link--active"
  >
    {linkTitle.title}
  </Link>
);

const renderNestedContents = (contents, linkTitles) =>
  contents.map((item, i) => {
    const isHorizontalRule = item === "___";
    // no id distinguishing hr, so disabled ES-Lint
    /* eslint-disable react/no-array-index-key */
    if (isHorizontalRule) {
      return <hr className="keyline" key={`___${i}`} />;
    }

    const linkTitlesForSection = linkTitles.filter(
      ({ sidebarSection }) => sidebarSection === item
    );
    const isCategory = !isHorizontalRule && linkTitlesForSection.length > 1;

    if (isCategory) {
      return (
        <div key={item} className="side-bar__category">
          <div className="subtitle-2">{capitalize(item.replace("-", " "))}</div>
          <div className="pl-4">{linkTitlesForSection.map(renderLink)}</div>
        </div>
      );
    }

    if (!linkTitlesForSection[0]) return null;

    return renderLink(linkTitlesForSection[0]);
  });

export default function SideBar({ linkTitles }) {
  return (
    <div className="md:py-8 md:pr-8 lg:py-12 lg:pr-12 md:sticky md:pin-t">
      {renderNestedContents(tableOfContents, linkTitles)}
    </div>
  );
}

SideBar.propTypes = {
  linkTitles: PropTypes.arrayOf(PropTypes.object)
};

SideBar.defaultProps = {
  linkTitles: []
};
